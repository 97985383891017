<template>
  <div class="box sc-width">
    <h1 class="main-title ">
      Notifications
    </h1>

    <div class="lists">
      <div class="lists-wrap">
        <Spin v-if="live.loading" fix></Spin>
        <div class="empty" v-if="live.list.length === 0">
          No Data
        </div>
        <ul class="contents" v-else>
          <li v-for="item in live.list" :key="item.id">
            <template v-if="item.type === 1">
              <strong>{{ item.content[0] }}</strong> from
              <strong>{{ item.content[1] }}</strong> started a group chat with
              you
              <router-link :to="`/chat?sid=${item.param}`"
                >Join Now</router-link
              >
              <p class="time">{{ item.created_at }}</p>
            </template>
            <template v-else-if="item.type === 2">
              <strong>{{ item.content[0] }}</strong> from
              <strong>{{ item.content[1] }}</strong> has exchanged business card
              with you
              <router-link :to="`/chat?company=${item.param}`"
                >Chat Now</router-link
              >
              <p class="time">{{ item.created_at }}</p>
            </template>
            <!-- <template v-else-if="item.type === 3">
              <strong>{{ item.content[0] }}</strong> from
              <strong>{{ item.content[1] }}</strong> has requested a sample for
              product {{ item.content[2] }}
              <router-link :to="`/requests/${item.param}`"
                >See Details</router-link
              >
              <p class="time">{{ item.created_at }}</p>
            </template> -->
            <template v-else-if="item.type === 3">
              You have received a sample request from
              <strong>{{ item.content[0] }}</strong
              >&nbsp;&nbsp;
              <router-link :to="`/requests/${item.param}`"
                >View Request</router-link
              >
              <p class="time">{{ item.created_at }}</p>
            </template>
            <template v-else-if="item.type === 4">
              <strong>{{ item.content[0] }}</strong> has Rejected your sample
              request for <strong>{{ item.content[1] }}</strong
              >&nbsp;&nbsp;
              <router-link :to="`/requests/${item.param}`"
                >View Request</router-link
              >
              <p class="time">{{ item.created_at }}</p>
            </template>
            <template v-else-if="item.type === 5">
              <strong>{{ item.content[0] }}</strong> has Accepted your sample
              request for <strong>{{ item.content[1] }}</strong
              >&nbsp;&nbsp;
              <router-link :to="`/requests/${item.param}`"
                >View Request</router-link
              >
              <p class="time">{{ item.created_at }}</p>
            </template>
            <template v-else-if="item.type === 6">
              Your sample request for <strong>{{ item.content[1] }}</strong> is
              on the way. The exhibitor has provided you with a tracking
              number.&nbsp;&nbsp;
              <router-link :to="`/requests/${item.param}`"
                >View Request</router-link
              >
              <p class="time">{{ item.created_at }}</p>
            </template>
          </li>
        </ul>
        <div class="page" v-if="live.total">
          <Page
            :page-size="live.limit"
            :page-size-opts="[10, 15, 20]"
            :total="live.total"
            show-sizer
            show-elevator
            show-total
            @on-change="pageChange($event, 'live')"
            @on-page-size-change="pageSizeChange($event, 'live')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import api from "@/api";
import moment from "moment";
const { _queryNotification } = api;

export default {
  data() {
    return {
      modType: "card",
      filter: {
        search: ""
      },
      live: {
        page: 1,
        limit: 10, // must in 5-20
        total: 0,
        list: [],
        loading: false
      }
    };
  },

  computed: {
    ...mapState("user", ["token"])
  },
  filters: {
    nameFilter(val) {
      if (val.length > 20) return val.substring(0, 20) + "...";
      else return val;
    },
    descFilter(val) {
      if (val.length > 80) return val.substring(0, 80) + "...";
      else return val;
    }
  },
  created() {
    this.queryNotification({
      state: "live"
    });
  },

  methods: {
    clearSearch() {
      ["live"].forEach(state => {
        this[state].page = 1;
        this.queryNotification({
          state
        });
      });
    },

    handleSearch() {
      ["live"].forEach(state => {
        this[state].page = 1;
        this.queryNotification({
          ...this.filter,
          state
        });
      });
    },

    pageChange(page, state) {
      this[state].page = page;
      this.queryNotification({
        state
      });
    },

    pageSizeChange(limit, state) {
      this[state].limit = limit;
      this.queryNotification({
        state
      });
    },

    queryNotification(obj) {
      this[obj.state].loading = true;

      _queryNotification({
        ...obj,
        page: this[obj.state].page,
        limit: this[obj.state].limit
      })
        .then(response => {
          this[obj.state].loading = false;
          const { data } = response;
          data.data.forEach(item => {
            var localTime = moment
              .utc(item.created_at)
              .local()
              .format("YYYY/MM/DD HH:mm:ss");
            var lastTime = new Date().getTime() - new Date(localTime).getTime();
            var days = 0;
            var hours = 0;
            var mins = 0;
            days = parseInt(lastTime / (24 * 60 * 60 * 1000));
            hours = parseInt(lastTime / (60 * 60 * 1000));
            if (days > 0) {
              item.created_at = days + " days ago";
            } else if (hours > 0) {
              mins = parseInt(lastTime / (60 * 60 * 1000));
              item.created_at = mins + " hours ago";
            } else {
              mins = parseInt(lastTime / (60 * 1000));
              item.created_at = mins + " min ago";
            }
          });
          this[obj.state].list = data.data;
          this[obj.state].total = data.total;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this[obj.state].loading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.main-title {
  position: relative;
  background: #fff;
  padding: 20px;
  border-bottom: 1px solid #ccc;
  .type-wrap {
    position: absolute;
    top: 22px;
    right: 20px;
    color: #ffca94;
    font-size: 20px;
    cursor: pointer;
    /deep/ .ivu-icon-ios-list {
      position: relative;
      top: 4px;
      font-size: 32px;
      font-weight: bold;
    }
    .cur {
      color: @primary-color;
    }
  }
}
.lists {
  position: relative;
  padding: 20px;
  // background-color: #fff;
  .empty {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.contents {
  list-style: none;
  border: 1px solid #ddd;
  border-radius: 6px;
  li {
    padding: 12px 15px;
    border-bottom: 1px solid #ddd;
    color: #888;
    &:last-child {
      border: none;
    }
    a {
      font-weight: bold;
      color: @primary-color;
      border-bottom: 1px solid @primary-color;
    }
    strong {
      color: #222;
    }
    .time {
      float: right;
      padding: 6px 0 4px;
      margin-top: -5px;
    }
  }
}
.page {
  display: flex;
  justify-content: flex-end;
  padding: 20px 15px;
  padding-right: 0;
}
.box {
  margin: 206px auto 0 auto;
}
</style>
